document.addEventListener('turbolinks:load', function() {
  const draggableElements = document.querySelectorAll('.draggable')
  const dropContainers = document.querySelectorAll('.drop-container')
  const submitButtons = document.querySelectorAll('.submit-form--drag-n-drop')

  /* Add draggable skills to Options */
  draggableElements.forEach(elem => {
    elem.dataset.status = 'dropped'

    elem.addEventListener('dragstart', () => {
      elem.classList.add('dragging')
      elem.dataset.status = 'dragging'

      elem.classList.remove('dragging--mobile')

      dropContainers.forEach(dropCont => {
        dropCont.classList.remove('drop-container--mobile')
      })
    })

    elem.addEventListener('dragend', () => {
      elem.classList.remove('dragging')
      elem.dataset.status = 'dropped'
    })

    // MOBILE EVENTS
    elem.addEventListener('touchstart', (e) => {
      e.preventDefault()

      if (elem.dataset.status === 'dragging') {
        elem.dataset.status = 'dropped'
        elem.classList.remove('dragging--mobile')
        elem.classList.add('draggable--mobile')

        dropContainers.forEach(dropCont => {
          dropCont.classList.remove('drop-container--mobile')
        })
      } else {
        draggableElements.forEach(dragElem => {
          dragElem.dataset.status = 'dropped'
          dragElem.classList.remove('dragging--mobile')
          dragElem.classList.add('draggable--mobile')
        })

        elem.dataset.status = 'dragging'
        elem.classList.remove('draggable--mobile')
        elem.classList.add('dragging--mobile')

        dropContainers.forEach(dropCont => {
          dropCont.classList.add('drop-container--mobile')
        })
      }
    })
  })

  /* Add droppable skills to Droppable Areas and Options Container */
  dropContainers.forEach(dropContainer => {
    // Disable Submit btn
    //   if has a match droppable area
    const matchDropArea = dropContainer.getAttribute('data-match')
    //   if has Min of Droppable Elements
    const minDropElements = dropContainer.getAttribute('data-drop-min')
    if (matchDropArea !== '' || minDropElements !== '') {
      setSubmitButtons('disabled', true)
    }

    dropContainer.addEventListener('dragover', e => {
      e.preventDefault()
      dropDraggable(
        document.querySelector('.dragging'), // draggable
        e.target,
        getDragAfterElement(e.target, e.clientY), // after element
        minDropElements
      )
    })

    // MOBILE EVENTS
    dropContainer.addEventListener('touchstart', (e) => {
      e.preventDefault()
      const draggable = document.querySelector('.dragging--mobile')
      if (e.target === dropContainer && draggable) {
        dropDraggable(
          draggable,
          e.target,
          getDragAfterElement(e.target, e.clientY), // after element
          minDropElements,
          true
        )
        if (draggable.dataset.status === 'dropped') {
          dropContainers.forEach(mobileDropContainer => {
            mobileDropContainer.classList.remove('drop-container--mobile')
          })
          draggable.classList.remove('dragging--mobile')
        }
      }
    })
  })

  function dropDraggable(draggable, dropContainer, afterElement, minDropElements) {
    let ableToDrop = false

    // Check if drop area has matching option
    ableToDrop = ableToDropCheckMatch(draggable, dropContainer, ableToDrop)
    // Check if this Droppable Area has a Max of Droppable Elements
    ableToDrop = ableToDropCheckMaxElem(dropContainer, ableToDrop)

    // Drop the element in this Droppable Area
    if (ableToDrop) {
      if (afterElement == null) {
        dropContainer.appendChild(draggable)
      } else {
        dropContainer.insertBefore(draggable, afterElement)
      }
      draggable.dataset.status = 'dropped'

      // Check if all Droppable Areas (except Options Container) has the Correct Answer => Show the Correct Answer Audio / Enable submit btn
      enableSubmitButtonsIfCorrectAnswers(dropContainer)

      // Check if all Droppable Areas (except Options Container) has the Min of Droppable Elements => Enable Submit Btns
      if (minDropElements !== '') {
        enableSubmitButtonsIfMinElements(minDropElements)
      }
    }
  }

  // ABLE TO DROP IF
  // A - There's no need to match
  // B — Draggable match data === Dropable Container match data
  function ableToDropCheckMatch(draggable, dropContainer, ableToDrop) {
    const matchDrag = draggable.getAttribute('data-match')
    const matchDrop = dropContainer.getAttribute('data-match')
    if ((matchDrag === '' && matchDrop === '') || (matchDrag !== '' && matchDrop !== '' && matchDrop != null && matchDrop.includes(matchDrag))) {
      ableToDrop = true
    }

    return ableToDrop
  }

  // ABLE TO DROP IF
  // A - Dropable Container DO NOT have maximum of elements
  // B — Dropable Container has less elements than Dropable Container's drop-max data
  function ableToDropCheckMaxElem(dropContainer, ableToDrop) {
    const maxDropElements = dropContainer.getAttribute('data-drop-max')
    const dropAreaElements = dropContainer.querySelectorAll('.kid-draggable-list-item').length
    if (maxDropElements !== '' && dropAreaElements >= maxDropElements) {
      ableToDrop = false
    }

    return ableToDrop
  }

  // Enable Submit Buttons And/OR show audio IF the answers are correct
  function enableSubmitButtonsIfCorrectAnswers(dropContainer) {
    let isCorrectAnswer = false
    const droppableAreas = document.querySelectorAll('.kid-droppable-box-item.drop-container')
    droppableAreas.forEach(dropArea => {
      const matchDrop = dropArea.getAttribute('data-match')
      const dropElements = dropArea.querySelectorAll('.kid-draggable-list-item')

      if (dropElements.length > 1) {
        let auxMatchDrop = matchDrop
        dropElements.forEach(dropEl => {
          const matchDropEl = dropEl.getAttribute('data-match')
          if (matchDrop !== '' && matchDropEl !== '' && matchDrop.includes(matchDropEl)) {
            auxMatchDrop = auxMatchDrop.replace(matchDropEl, '')
          }
        })
        auxMatchDrop = auxMatchDrop.replace(/\s/g, '')
        if (auxMatchDrop === '') {
          isCorrectAnswer = true
        } else {
          isCorrectAnswer = false
        }
      } else if (dropElements.length === 1) {
        const matchDropEl = dropElements[0].getAttribute('data-match')
        if (matchDrop !== '' && matchDropEl !== '' && matchDrop === matchDropEl) {
          isCorrectAnswer = true
        } else {
          isCorrectAnswer = false
        }
      } else {
        isCorrectAnswer = false
      }
    })

    const correctAnswers = document.querySelector('.kid-draggable-list.drop-container').getAttribute('data-match')
    let correctAnswerAudio = null
    correctAnswerAudio = document.querySelector('.correct-answer-audio[data-match=\'' + correctAnswers + '\']')

    if (correctAnswerAudio !== null) {
      if (isCorrectAnswer && dropContainer.hasAttribute('data-drop-area')) {
        correctAnswerAudio.style.display = 'flex'
        setSubmitButtons('enabled')
      } else {
        correctAnswerAudio.style.display = 'none'
        setSubmitButtons('disabled')
      }
    } else if (dropContainer.getAttribute('data-match') !== '') {
      if (isCorrectAnswer) {
        setSubmitButtons('enabled')
      } else {
        setSubmitButtons('disabled')
      }
    }
  }

  // Enable Submit Buttons IF the droppable areas have the minimum elements
  function enableSubmitButtonsIfMinElements() {
    const droppableAreas = document.querySelectorAll('.kid-droppable-box-item.drop-container')
    let enableSubmit = false

    for (const dropArea of droppableAreas) {
      const dropElements = dropArea.querySelectorAll('.kid-draggable-list-item')
      const dropAreaMinDropEl = dropArea.getAttribute('data-drop-min')

      // eslint-disable-next-line eqeqeq
      if (dropElements.length >= dropAreaMinDropEl) {
        enableSubmit = true
      } else {
        enableSubmit = false
        break
      }
    }

    if (enableSubmit) {
      setSubmitButtons('enabled')
    } else {
      setSubmitButtons('disabled')
    }
  }

  function getDragAfterElement(container, y, mobile = false) {
    let draggableElements = []
    if (mobile) {
      draggableElements = [...container.querySelectorAll('.draggable:not(.dragging--mobile)')]
    } else {
      draggableElements = [...container.querySelectorAll('.draggable:not(.dragging)')]
    }
    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect()
      const offset = y - box.top - box.height / 2
      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: child }
      } else {
        return closest
      }
    }, { offset: Number.NEGATIVE_INFINITY }).element
  }

  function setSubmitButtons (status, setup = false) {
    submitButtons.forEach(btn => {
      if (status === 'disabled') {
        if (!setup || !btn.dataset.alreadySubmitted) {
          btn.classList.add('btn-disabled')
          btn.classList.remove('btn-primary')
        }
      } else {
        btn.classList.add('btn-primary')
        btn.classList.remove('btn-disabled')
      }
    })
  }
})
