document.addEventListener('turbolinks:request-start', function() {
  document.querySelectorAll('.loading').forEach(loading => {
    loading.style.display = 'flex'
  })
})

document.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('.loading').forEach(loading => {
    loading.style.display = 'none'
  })
})
