let videos = []
let audios = []

document.addEventListener('turbolinks:load', function() {
  const containers = document.querySelectorAll('.audio-container')
  containers.forEach(container => {
    container.classList.remove('audio-playing')
    showHideReplay(container)
  })

  const playButtons = document.querySelectorAll('.audio-button')
  const replayButtons = document.querySelectorAll('.audio-button-replay')
  videos = document.querySelectorAll('video')
  audios = []
  // get audios
  document.querySelectorAll('button[data-audio]:not([data-audio=\'\'])').forEach((audio, index) => {
    const audioSource = audio.getAttribute('data-audio')
    audios.push([audioSource, new Audio(audioSource)])
  })
  // when audio ends
  audios.forEach(audio => {
    audio[1].addEventListener('ended', () => {
      const container = document.querySelector('button[data-audio=\'' + audio[0] + '\']').closest('.audio-container')
      container.classList.remove('audio-playing')
      container.classList.remove('audio-paused')
      showHideReplay(container)
    })
  })
  // when browser history changes
  window.addEventListener('popstate', function() {
    audios.forEach(audio => {
      audio[1].pause()
    })
    audios = []

    videos.forEach(video => {
      video.remove()
    })
  })

  playButtons.forEach(button => {
    button.addEventListener('click', (e) => {
      const container = e.target.closest('.audio-container')
      const clickedAudioSource = e.target.closest('button[data-audio]:not([data-audio=\'\'])').getAttribute('data-audio')
      const clickedAudio = audios.find(audio => audio[0] === clickedAudioSource)

      // pause clicked audio
      if (container.classList.contains('audio-playing')) {
        if (clickedAudio != null) {
          container.classList.remove('audio-playing')
          container.classList.add('audio-paused')
          showHideReplay(container)
          clickedAudio[1].pause()
        }
      } else { // pause all audios and play the clicked one
        const containers = document.querySelectorAll('.audio-container')
        containers.forEach(c => {
          c.classList.remove('audio-playing')
          showHideReplay(c)
        })
        audios.forEach(audio => {
          audio[1].pause()
        })

        if (clickedAudio != null) {
          container.classList.add('audio-playing')
          showHideReplay(container)
          clickedAudio[1].play()
        }
      }
    })
  })

  replayButtons.forEach(button => {
    button.addEventListener('click', (e) => {
      const container = e.target.closest('.audio-container')
      const clickedAudioSource = container.querySelector('button[data-audio]:not([data-audio=\'\'])').getAttribute('data-audio')
      const clickedAudio = audios.find(audio => audio[0] === clickedAudioSource)

      if (clickedAudio != null) {
        container.classList.remove('audio-paused')
        if (!container.classList.contains('audio-playing')) {
          container.classList.add('audio-playing')
        }
        showHideReplay(container)
        clickedAudio[1].currentTime = 0
        clickedAudio[1].play()
      }
    })
  })

  const tabButtons = document.querySelectorAll('.tab-button')
  // If Some Tab is Clicked
  tabButtons.forEach(tabBtn => {
    tabBtn.addEventListener('click', (e) => {
      // pause all audios
      audios.forEach(audio => {
        const container = document.querySelector('button[data-audio=\'' + audio[0] + '\']').closest('.audio-container')

        if (container.classList.contains('audio-playing')) {
          if (audio != null) {
            container.classList.remove('audio-playing')
            container.classList.add('audio-paused')
            // eslint-disable-next-line no-undef
            showHideReplay(container)
            audio[1].pause()
          }
        }
      })
      // pause all videos
      videos.forEach(video => {
        video.pause()
      })
      const clickedVideo = e.target.closest('label').nextElementSibling.querySelector('video')

      if (clickedVideo != null && clickedVideo.currentTime === 0) {
        clickedVideo.play()
      }
    })
  })
})
// stop audios and videos on change page
// when btns are clicked
document.addEventListener('turbolinks:request-start', function() {
  if (audios != null && audios !== []) {
    audios.forEach(audio => {
      audio[1].pause()
    })
    audios = []
  }

  if (videos != null && videos !== []) {
    videos.forEach(video => {
      video.remove()
    })
  }
})

// Visual Changes
function showHideReplay(container) {
  const play = container.querySelector('.audio-button-play')
  const pause = container.querySelector('.audio-button-pause')
  const replay = container.querySelector('.audio-button-replay')
  if (container.classList.contains('audio-playing')) {
    play.style.display = 'none'
    pause.style.display = 'flex'
    replay.style.display = 'flex'
  } else {
    pause.style.display = 'none'
    replay.style.display = 'none'
    play.style.display = 'flex'
  }
}
