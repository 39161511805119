// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application'
import '../general/accordion'
import '../general/navbar'
import '../general/draggable'
import '../general/audio_and_video'
import '../general/tab'
import '../general/toggle'
import '../general/toasts'
import '../general/loading'

import 'chartkick/chart.js'
import '../general/charts'
import 'admin/nested_form_fields'

import * as Sentry from '@sentry/browser'
// import { BrowserTracing } from '@sentry/tracing'

if (process.env.RAILS_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8dd84ce73d3b403ca69c0552b30c3e02@errors.hyp.pt/3'
    // integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0
  })
}

// const moment = require('moment')
// moment.locale('pt-PT')

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('trix')
require('@rails/actiontext')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars

// require.context('../videos', true)

require.context('../audios', true)

require.context('../pdfs', true)
