document.addEventListener('turbolinks:load', function() {
  const accordions = document.querySelectorAll('.accordion-title')

  accordions.forEach(ac => {
    toggleContent(ac)
    ac.addEventListener('click', (e) => {
      let acTitle = e.target
      if (!acTitle.classList.contains('accordion-title')) {
        acTitle = acTitle.closest('.accordion-title')
      }
      acTitle.classList.toggle('active')
      acTitle.querySelector('i').classList.toggle('uil-plus')
      acTitle.querySelector('i').classList.toggle('uil-minus')

      toggleContent(ac)
    })
  })
})

function toggleContent(acTitle) {
  const acContent = $(acTitle).closest('.accordion').find('.accordion-content')[0]
  if ($(acTitle)[0].classList.contains('active')) {
    let contentHeight = 0
    for (const item of acContent.children) {
      contentHeight += item.offsetHeight
    }
    acContent.style.height = contentHeight + 'px'
  } else {
    acContent.style.height = 0
  }
}
