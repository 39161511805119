document.addEventListener('turbolinks:load', function() {
  // TAB BUTTONS
  const buttons = document.querySelectorAll('.kid-tab-steps-answer-button')
  const disabledButton = document.querySelector('.tab-label--disabled')

  buttons.forEach(button => {
    button.addEventListener('click', function() {
      this.classList.add('btn-disabled')
      const text = document.querySelector('[data-answer-tab="' + this.dataset.tab + '"]')
      text.classList.add('kid-tab-steps-answer-reveal')
      disabledButton.classList.remove('tab-label--disabled')
    })
  })

  // SPECIFIC ELEMENTS ENABLE SUBMIT BUTTON
  // // ON CLICK
  const specificClickButtons = document.querySelectorAll('.able-submit-on-click')
  const specificClickDisabledButton = document.querySelector('.btn-disabled.submit-form')

  specificClickButtons.forEach(button => {
    button.addEventListener('click', function() {
      if (specificClickDisabledButton.classList.contains('btn-disabled')) {
        specificClickDisabledButton.classList.remove('btn-disabled')
        specificClickDisabledButton.classList.add('btn-primary')
      }
    })
  })
})
