document.addEventListener('turbolinks:load', function() {
  const tabs = document.querySelectorAll('.tab-button')
  const buttonsToAble = document.querySelectorAll('.able-after-tabs')

  tabs.forEach(tab => {
    tab.addEventListener('click', function() {
      this.classList.add('opened')
      ableButtonsIfTabsOpened()
    })
  })

  const ableButtonsIfTabsOpened = () => {
    if (document.querySelectorAll('.tab-button.opened').length === tabs.length) {
      buttonsToAble.forEach(btn => {
        btn.classList.remove('btn-disabled')
        btn.classList.add('btn-primary')
      })
    }
  }
})
