document.addEventListener('turbolinks:load', function() {
  const weeklyChartContainer = document.querySelector('.weekly-chart-container')

  if (weeklyChartContainer) {
    const weeklyChart = weeklyChartContainer.querySelector('canvas')

    window.addEventListener('resize', function() {
      weeklyChart.style.width = 0
      const width = weeklyChartContainer.offsetWidth
      weeklyChart.style.width = width + 'px'
    })
  }
})
